.legend {
  background-color: $white;
  padding: 2em;
  border-radius: 4px;
  box-shadow: $shadow__default;
  margin-top: 1em;
  font-size: .9em;

  &--title {
    font-size: 1.3em;
    font-weight: normal;
  }

  .legend-items {
    &--item {
      display: flex;
      align-items: center;
      margin: 0.5em 0;
    }
  }
}
