.form__element__clickable {
  cursor: pointer;
}

input, textarea, select, .form__element__select {
  border: 1px solid lighten($lightest-grey, 4%);
  box-shadow: $shadow__default;
  background-color: $white;
  border-radius: .5em;
  padding: .3em 2em .4em .7em;
  font-size: .86em;
}
input[type=submit] {
  font-size: 1em;
}

.input--lg {
  padding: .6em 2em .7em .7em;
}

input[type="checkbox"], input[type="radio"] {
  font-size: 1em;
}

::placeholder{
  color: $lighter-grey;
}

.checkmark--wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark--wrapper input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $light-grey;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.checkmark--wrapper:hover input ~ .checkmark {
  background-color: $lightest-grey;
}

.checkmark--wrapper input:checked ~ .checkmark {
  background-color: $ocean-blue;
}

.checkmark--wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkmark--wrapper  .checkmark:after {
  left: 6px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark--wrapper input:focus-visible ~ .checkmark { 
  outline:2px solid $ocean-blue; 
  box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: .5);
}

label, .label {
  display: block;
  font-weight: $base-font-semibold;
  margin-bottom: .3em;
  font-size: .86em;
}

.form__multistep {
  input[type='password'], input[type='text'], textarea {
    margin-bottom: 2em;
    width: 95.5%;
    padding: 0.3em;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  &:hover{
    box-shadow: $shadow__hover;
  }
}

select:focus-visible, input:focus-visible { 
  outline:2px solid $ocean-blue; 
  box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: .5);
}

//For IE
select::-ms-expand {
  display: none;
}

select option{
  background-color: rgba(255, 255, 255, 0.08);
  box-shadow: inset 2px 2px rgba(255, 255, 255, 0.08);
  &:hover{
    background-color: $ocean-blue;
    box-shadow: inset 2px 2px $ocean-blue;
    color: $white;
  }
}

.form--controls {
  margin-bottom: 2em;
  display: flex;
}

.form--controls__group {
  display: inline-flex;
  flex-direction: row;
}

.form--controls__group li {
  display: flex;
}

.dropdown__collection {
  .form__dropdown label {
    display: inline-block;
    text-align: left;
    padding-right: .75em;
  }

  .dropdown__element {
    width: 35em;
  }

  .collection-chooser {
    font-size: 0.86em;
  }
}

.search__box {
  width: 100%;
  margin-right: 1em;
}

.search__wrapper {
  position: relative;
  .form__dropdown label {
    position: absolute;
    top: -18px;
  }
  .form__error {
    display: none;
  }
  .search {
    width: 100%;
    padding-right: .4em;
  }
  
  button[aria-label="Clear"] {
    position: absolute;
    right: 1.4em;
  }
}

.search__wrapper.form-group__element {
  margin-right: 0em;
}

.search__icon {
  &:after {
    position: absolute;
    font-family: 'FontAwesome';
    content: '\f002';
    font-weight: 900;
    right: 1em;
    top: .34em;
  }
}

.search__loading {
  .spinner {
    position: absolute;
    right: 10px;
    top: 6px;
    > div {
      width: 8px;
      height: 8px;
      background-color: $black;
    }
  }
}

.search__results {
  background-color: $white;
  border: 1px solid $lightest-grey;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: .2em;
  -webkit-border-radius: .2em;
  -moz-border-radius: .2em;
  position: absolute;
  top: 36px;
  z-index: 100;
  max-height: 18em;
  overflow-y: scroll;

  a, a:visited {
    padding: .7em 1.8em .7em 1.2em;
    color: $black;
    border-bottom: 1px solid $lightest-grey;
    display: block;
    &:hover {
      background-color: darken($background-white, 2%);
    }
  }
}

.form__bulkgranules {
  min-height: 1700px;
}

.form__dropdown {
  overflow: visible;
}

.form-group__element {
  margin-right: 1em;
}

.form-group__element--small {
  font-size: .86em;
}

.form__element__select {
  font-weight: $base-font-regular;
  padding: .3em .9em;
  input {
    margin-right: .8em;
  }
}

.form__element__updateToggle {
  padding-right: .1em;
  margin-left: auto;
  align-self: flex-end;
  margin-top: 1em;
  .metadata__updated {
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
  .form-group__updating {
    margin-left: .2em;
    font-weight: $base-font-semibold;
    font-size: .86em;
    min-width: 120px;
  }
}

.form__element__refresh {
  &:before {
    font-family: 'FontAwesome';
    content: '\f021';
    color: $ocean-blue;
    font-weight: 900;
    cursor: pointer;
    transition: transform 0.2s;
  }
  &:hover {
    transform: rotate(0.1turn);
  }
}

.form__element__updateToggle-noHeader {
  margin-left: auto;
}

.form-group__element--right {
  float: right;
  margin-left: 2em;
  margin-right: 0;
}

.form-group__element--right--sm {
  margin-top: 0;
}

.form-group__element--left {
  margin-right: 1em;
  margin-top: 2em;
  margin-left: 1em;
}

.form__item {
  margin-bottom: 1.5em;

  &--tooltip {
    display: flex;
    align-items: center;

    svg {
      color: $ocean-blue;
      margin-left: .5em;
      padding: .5em;
    }
  }

  .radio {
    display: flex;

    label {
      margin: 1em 2em 1em 0;

      input {
        margin-right: .5em;
      }
    }
  }
}

.subform__item,
.form__addone--item {
  margin: 0.5em 0;
}

.form__addone--input {
  width: 50%;
  margin-right: 0.5em;
}

.form__addone--button {
  padding: 0.3em 1em;
  font-size: 1.4em;
  float: right;
}

.subform__item {
  transition: all 0.2s ease 0s;
  width: 87.5%;
}

.subform__item--expanded {
  background-color: lighten($lightest-grey, 7%);
  border: 1px solid $lightest-grey;
  padding: 1.5em 2em 2.5em;
  border-radius: .4em;
  -webkit-border-radius: .4em;
  -moz-border-radius: .4em;
  .form__item {
    margin-bottom: 1.8em;
  }
  .subform__name {
    margin-bottom: 2em;
  }
  .subform__button {
    display: none;
  }
}

.subform__name {
  display: inline-block;
}

.subform__button {
  margin-left: 1em;
  background: none;
  border: none;
}

.subform__fields {
  margin-top: 1em;
}

.subform__item--expanded .subform__remove {
  color: $error-red;
  border-bottom: 2px solid $error-red;
  &:hover {
    color: $grey;
    border-bottom: 2px solid $grey;
  }
}

.main-form--wrapper {
  background-color: $white;
  padding: 2em;
  margin-bottom: 2em;
  border: 1px solid $border-grey;
  box-shadow: $shadow__default;
}

.page__content--shortened--centered {
  .page__section--fullpage-form {
    .form__item {
      input {
        padding: 10px;
        &[type=text], &[type=password] {
          width: 100%;
        }
      }
      .react-datetime-picker__inputGroup {
        input {
          padding: 0;
        }
      }
      .subform__item--expanded {
        width: 87%;
      }
      .form__addone--item {
        input {
          width: 93%;

          @media screen and (max-width: 1240px) {
            width: 90%
          }

          @media screen and (min-width: 1600px) {
            width: 95%
          }
        }
        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }
    }
  }
}

.page__section--fullpage-form--internal {
  .form__item {
    margin-bottom: 3em;
    input{
      padding: 10px;
      &[type=text] {
        width: 70%;
      }
    }
    .subform__item--expanded {
      width: 70%;
      margin-top: 1.5em;
      input[type=text] {
        width: 92%;
      }
      .form-group__element--left {
        margin-top: 0;
      }
    }
    .dropdown__wrapper {
      width: 50%;
      margin-top: .2em;
      float: left;
      select {
        width: 100%;
      }
    }
    .form__addone--item {
      margin-bottom: 1em;
      input {
        width: 93%;
      }
    }
    &--require__description {
      font-size: .86em;
      text-align: right;
    }
  }
}

.create-report {
  .form__dropdown {
    width: 50%;
  }

  .main-form--wrapper {
    padding-bottom: 5em;
    .form__dropdown {
      width: 92%;
    }
  }
}

.label__description {
  display: block;
  font-size: 1em;
  margin: .2em 0 .6em;
}

.label__required {
  color: $error-text-red;
}

/*log in*/
.form__login {
  margin-top: 1.5em;
  .form__text {
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 2.5em;
    }
    input {
      width: 80%;
    }
  }
}

.form__error--wrapper {
  input,
  select {
    border-color: $error-red;
  }
}

.form__error {
  font-size: 1em;
  display: block;
  margin-bottom: 0.8em;
  color: $error-red;
}

.error__report {
  background-color: $white;
  border-radius: 0.5em;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .08);
  padding: 1.1em 1.4em;
  text-align: center;
  position: relative;
  margin: 2em 0em;
  color: $error-text-red;
  font-size: .86em;
  border: 1px solid $error-red;
  p {
    margin-bottom: 0;
    overflow-x: scroll;
    color: $error-text-red;
  }
}

.page__section__header-wrapper {
  .error__report {
    margin-top: 3em;
    margin-bottom: 0;
  }
}

.autocomplete__menu {
  position: fixed;
  overflow: auto;
  max-height: 50%;
  z-index: 99;
  background-color: $white;
  border: 1px solid $lightest-grey;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: .2em;
}

.autocomplete__select.dropdown-item {
  cursor: pointer;
  padding: .3em .9em;
  font-size: .86em;

  &:hover {
    background-color: $hover-blue;
    border: none;
  }
}

.subform__item--last .subform__button {
  position: relative;
  padding: .65em 1.2em .65em 2.5em;
  border-radius: 4px;
  color: $white;
  background-color: $ocean-blue;
  font-weight: $base-font-regular;
  font-size: .86em;
  text-align: center;
  border: none;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  overflow: hidden;
  input {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
  }
  :hover {
    background-color: darken($light-green, 5%);
    color: $white;
    border: 0;
  }
}

.subform__item--last {
  .subform__button {
    display: inline-block;
    margin-top: 1.5em;
    margin-left: 0;
  }
  .subform__name {
    display: none;
  }
}

.rule__status {
  display: flex;
    flex-direction: row;
    align-items: center;
    flex: 12;
}

.rule__content,
.execution__content,
.provider__content {
  border: 1px solid $border-grey;
  box-shadow: $shadow__default;
  border-radius: 10px;
  padding: 25px 40px;
  margin-top: 15px;

  .metadata__details {
    font-size: $base-font-size;
    display: flex;
    flex-direction: column;

    .meta__row {
      flex-direction: row;
      dt{
        width: 25%;
      }
      dd {
        width: 74%;
        line-break: anywhere;
        margin: 0;
      }
    }
  }
}

.execution__content {
  &--visual {
    width: 25%;
    margin: 0 auto;
  }

  .metadata__details .meta__row {
    dd {
      width: 80%;
      margin: 0;
    }

    dt {
      width: 20%;
      padding: 0;
    }
  }
}

.execution__visual {
  .header {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: .5em 2em;
    box-shadow: $shadow__default;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
  }

  .execution__content {
    cursor: pointer;
    margin-top: 0;
    border-radius: 0 0 10px 10px;
  }
}

.reconciliation-granule__content {
  .metadata__details .meta__row {
    dt, dd {
      vertical-align: top;
    }
    dt {
      width: 20%;
    }
    dd {
      width: 40%;
    }
    .granule__location div{
      display: inline-block;
      width: 30%;
    }
  }
}

.reconciliation-reports__header {
  &--tooltip {
    display: flex;
    align-items: center;

    svg {
      color: $ocean-blue;
      margin-left: .5em;
      padding: .5em;
    }
  }
}
