.datetime{
  border-radius: 0.5em;
  &__info{
    font-weight: $base-font-regular;
  }
  &__header{
    display: flex;
    justify-content: space-between;
  }
  &__header li:first-child{
    float: left;
  }
  &__header li:nth-child(2){
    float: right;
  }
  &__wrapper{
    background-color: $white;
    padding: 1.5em 1em .5em;
    display: inline-block;
    border-radius: .5em;
    border: 1px solid $border-grey;
    box-shadow: $shadow__default;
    font-size: .86em;
    width: 1015px;
    height: 127px;
    margin: .8em 1.5em .8em 0;
    color: #8C8C8C;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    clear: right;
    position: absolute;
    z-index: 0;
    top: 268px;
  }
  &__wrapper h3{
    font-size: 1.3em;
    font-weight: $base-font-semibold;
    margin-bottom: .5em;
  }
  &__wrapper hr{
    height: 1px;
    background-color: $lightest-grey;
    border: none;
    margin-bottom: 1em;
    margin-top: .25em;
  }
  &__internal{
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 1em 0;
  }
  &__internal > li{
    justify-content: space-evenly;
  }

  &__internal label{
    font-size: 1em;
    font-weight: $base-font-regular;
    margin-bottom: .4em;
  }

  &.selector__hrformat {
    color: $grey;
    width: 80px;
  }

  &.dropdown__dtrange {
    color: $grey;
    width: 135px;
  }

  &__range{
    z-index: 1;
    position: relative;
    top: 73px;
    display: flex;
    justify-content: center;
  }

  &__clear{
    position: relative;
    margin: .7em 0em 0em 0em !important;
    float: right;
    &:after {
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f00d';
      color: $white;
      font-weight: 900;
      top: .25em;
      left: 1em;
    }
  }

  &__refresh{
    position: relative;
    &:after {
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f01e';
      color: $white;
      font-weight: 900;
      top: .25em;
      left: .7em;
    }
  }

  &__range_wrapper{
    width: 1040px;
  }
}
