/***** Fonts Imports ********/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700&display=swap");

/******* Typography *******/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  margin-bottom: 0.5em;
}

.heading--xxlarge {
  font-size: 3.2em;
  font-weight: $base-font-bold;
  color: $white;
}

.heading--xlarge {
  font-size: 2em;
  font-weight: $base-font-semibold;
  color: $white;
}

.heading--large {
  font-size: 1.25em;
  font-weight: $base-font-regular;
}

.heading--medium {
  font-size: 1.125em;
  font-weight: $base-font-regular;
}

.heading--small {
  font-size: 1em;
  font-weight: $base-font-bold;
}

.heading--description {
  font-size: 0.86em;
}

.heading--shared-content {
  display: inline-block;
}

.heading--shared-content--right {
  display: inline-block;
  margin-right: 0.6em;
}

.heading__wrapper {
  &--border {
    margin-bottom: 1em;
    margin-top: 1.3em;
    padding: 0.8em 1em 0.3em 1em;
    background-color: #f3f3f3;
    h2,
    h3,
    h4 {
      margin-bottom: 0.5em;
    }
  }
  &--topborder {
    border-top: 1px solid $lightest-grey;
    margin-top: 1em;
    padding-top: 1.5em;
  }
}

.with-description,
.description {
  margin: 0.5em 0;
}

.with-bottom-border {
  padding-bottom: 15px;
  border-bottom: 1px solid $lightest-grey;
}

.breadcrumb,
.caption {
  font-size: 0.875em;
}

.caption {
  color: $lighter-grey;
  margin-bottom: 2em;
  line-height: 1.6em;
}

.description {
  font-style: italic;
  font-weight: 400;
}
