/* EUI Colors */
$light-green: #20ce6f;
$ocean-blue: #2072a4;
$midnight-blue: #2c3e50;
$royal-purple: #673285;
$dolphin-grey: #95a5a6;
$sky-blue: #7ab5da;

/* Cumulus Secondary Blues */
$sub-blue: #1E6B9D;
$dark-blue: #1A557B;
$hover-blue: #EBF3F8;

/* Errors & Warnings */
$error-red: #E74C3C;
$error-text-red: #EC1600;
$yellow: #EED919;
$orange: #F9A213;

/* Black, Grey & White */
$black: #36342D;
$dark-grey: #4A4A4A;
$grey: #8C8C8C;
$light-grey: #C4C4C4;
$lighter-grey: #8C8C8C;
$lightest-grey: #E2DFDF;
$border-grey: #ECEAEA;
$background-white: #F8F8F8;
$white: #FFFFFF;

/* Theme */
$theme--blue: #2276ac;
$theme--light: #E2DFDF;
$theme--dark: #2c3e50;
$theme--success: $light-green;
$theme--conflict: $orange;


:export {
    borderGrey: $border-grey;
    hoverBlue: $hover-blue;
    oceanBlue: $ocean-blue;
    white: $white;
}
