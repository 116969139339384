.dropdown {
  background-color: $white;

  &__options {
    position: relative;
  }

  &__options__btn {
    &:before {
      position: absolute;
      color: $white;
      position: absolute;
      font-family: "FontAwesome";
      content: "\f013";
      font-weight: 900;
      left: 10px;
      top: 0.45em;
    }
  }

  // Removes console warning described here https://github.com/react-bootstrap/react-bootstrap/issues/5081
  & &-menu {
    margin: 0;
  }

  &__menu {
    position: absolute;
    background-color: #fff;
    right: 0;
    z-index: 15;
    margin-top: 1em;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5em 0;
    min-width: 12em;
    li {
      display: block;
      padding: 0.8em 1.5em;
      border-bottom: 1px solid darken($background-white, 2%);
      a {
        display: block;
        color: $dark-grey;
        &:hover {
          color: $ocean-blue;
        }
        &.async__element--loading {
          color: $lightest-grey;
        }
        &.async__element--disabled {
          color: $light-grey;
          cursor: default;
        }
      }
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  &__menu--hidden {
    display: none;
    visibility: hidden;
  }

  &__collection {
    margin-bottom: 0em;
  }

  .react-select {
    &__control {
      border: 1px solid $border-grey;
      border-radius: 0.5em;
      box-shadow: $shadow__default;
    }
    &__menu {
      margin: 0;
      z-index: 9999;
    }
    &__indicator {
      background-color: $ocean-blue;
      border-radius: 0 0.5em 0.5em 0;
      box-shadow: $shadow__default;
      svg {
        display: none !important;
      }
      &:after {
        font-family: "FontAwesome";
        content: "\f0dc";
        color: $white;
      }
    }
    &__indicator-separator {
      display: none;
    }

    &__option {
      &.react-select__option--is-focused {
        background-color: $hover-blue;
      }
      &.react-select__option--is-selected {
        background-color: $ocean-blue;
        color: $white;
      }
    }
  }
}
