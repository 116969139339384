$delete-btn-icon: '\f187';
$delete-btn-bg-color: $error-red;
$delete-btn-hover-bg-color: darken($error-red, 10%);

.button {
  position: relative;
  padding: .65em 1.2em .65em 2.5em;
  border-radius: 4px;
  color: $white;
  background-color: $ocean-blue;
  font-weight: $base-font-regular;
  text-align: center;
  border: none;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  overflow: hidden;
  input {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
  }

  &:visited {
    color: $white;
  }

  &:disabled,
  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &--hidden {
    visibility: hidden;
    display: none;
  }

  &:hover{
    background-color: $midnight-blue;
    color: $white;
  }

  &--oauth{
    padding: 1em;
  }

  &--small{
    font-size: .86em;
    padding: .4em 1.5em .4em 2.5em;

    &.button--no-left-padding {
      padding: .4em 1.5em;
    }
  }

  &--events{
    background-color: $light-green;
    padding: .4em 1.5em .4em 1.5em;

    &:hover {
      background-color: darken($light-green, 10%);
      color: #fff;
      border: 0;
    }
  }

  &--large{
    padding: .7em 2em;
    font-weight: $base-font-bold;
    font-size: 1em;
  }

  &--round {
    border-radius: 50%;
    height: 2em;
    width: 2em;
    background-color: $sub-blue;
    border: 1px solid #ffffff;
  }

  &--no-left-padding {
    padding: .65em 1.2em;
  }

  &--white, &--white:visited {
    color: $midnight-blue;
    background-color: $white;

    &:hover{
      color: $white;
      background-color: $midnight-blue;
    }
  }

  &--green, &--green:visited {
    background-color: $light-green;

    &:hover {
      background-color: darken($light-green, 10%);
      color: #fff;
      border: 0;
    }
  }

  &--failed {
    background-color: $error-red;
    position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        font-weight: 900;
        left: 10px;
      }
      &:hover {
        background-color: $delete-btn-hover-bg-color;
        color: #fff;
        border: 0;
      }
  }

  &--primary, &--primary:visited {
    &:hover {
      background-color: darken($ocean-blue, 10%);
      color: $white;
      border: 0;
    }
  }

  &--secondary, &--secondary:visited{
    background-color: $dolphin-grey;
    &:hover {
      background-color: darken($dolphin-grey, 20%);
    }
  }

  &--top{
    background-color: $light-green;
    color: $white;
    border: none;
    border-radius: 4px;
    padding: .65em 1.2em .65em 2.5em;
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;

    &:before{
      color: $white;
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f0aa';
      font-weight: 900;
      left: 13px;

    }

    &:hover{
      &:hover {
        background-color: darken($light-green, 5%);
      }
    }
  }

  &--close{
    padding: .65em 1.2em .65em 1.2em;
    background-color: $light-green;
    
    &:hover {
      background-color: darken($light-green, 10%);
    }
  }

  &--show {
    padding: .25em .75em;
  }

  &--close, &--show {
    margin: 1em 0 .75em 0;
  }

  /*******ButtonGroup & Group CTA******/
  &--group-action {
    background-color: $light-green;
    position: relative;
    &:hover {
      background-color: darken($light-green, 10%);
    }

    &--collapsed {
      background-color: $midnight-blue;
      color: $white;
      &:hover {
        background-color: lighten($midnight-blue, 20%);
      }
    }

    &:before, &--collapsed:before {
      color: $white;
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f144';
      font-weight: 900;
      left: 10px;
    }
  }

    &--add {
      background-color: $light-green;
      position: relative;

      &:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f067';
        font-weight: 900;
        top: 4px;
        left: 10px;
      }
    }

    &--cancel {
      background-color: $dolphin-grey;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f057';
        font-weight: 900;
        left: 10px;

      }
    }

    &--cancel:hover:before{
      color: $white;
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f057';
      font-weight: 900;
      visibility: hidden;
  }

    &--edit {
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f044';
        font-weight: 900;
        left: 10px;

      }
    }

    &--delete {
      background-color: $delete-btn-bg-color;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: $delete-btn-icon;
        font-weight: 900;
        left: 10px;
      }
      &:hover {
        background-color: $delete-btn-hover-bg-color;
        color: #fff;
        border: 0;
      }
    }

    &--remove {
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0a0';
        font-weight: 900;
        left: 10px;

      }
    }

    &--execute{
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f5fc';
        font-weight: 900;
        left: 10px;

      }
    }

    &--reingest{
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f233';
        font-weight: 900;
        left: 10px;

      }
    }

    &--remove, &--execute, &--reingest {
      &:hover {
        background-color: darken($light-green, 10%);
        color: #fff;
        border: 0;
      }
    }

    &--download{
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0ab';
        font-weight: 900;
        left: 10px;
      }

      &.button__animation:hover:before {
        visibility: hidden;
      }
    }

    &--copy{
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0c5';
        font-weight: 900;
        left: 10px;

      }
    }

    &--confirm, &--submit{
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0c7';
        font-weight: 900;
        left: 10px;

      }
    }

    &--confirm:hover:before, &--submit:hover:before{
      color: $white;
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f0c7';
      font-weight: 900;
      visibility: hidden;
    }

    &--enable {
      background-color: $light-green;
      position: relative;

      &:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f205';
        font-weight: 900;
        top: 4px;
        left: 10px;
      }
    }

    &--disable {
      background-color: $light-green;
      position: relative;

      &:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f204';
        font-weight: 900;
        top: 4px;
        left: 10px;
      }
    }

    &--file {
      &:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f15c';
        font-weight: 900;
        top: 4px;
        left: 10px;
      }
    }

    &--close-sidebar {
      &:before {
        color: $white;
        font-family: 'FontAwesome';
        content: '\f0d9';
        font-size: 17.5px;
        align-content: center;
        align-items: center;
        align-self: center;
        justify-content: center;
        text-align: center;

      }
    }

    &--open-sidebar {
      &:before {
        color: $white;
        font-family: 'FontAwesome';
        content: '\f0da';
        font-size: 17.5px;
        align-items: center;
        align-content: center;
        align-self: center;
        justify-content: center;
        text-align: center;
      }
    }


     /*******Button Animations & Transitions ******/
    &--loading {
      position: relative;
      display: inline-block;
      .spinner {
        position: absolute;
        top: 6px;
        left: 0;
        width: 100%;
      }
      color: $light-green;
    }

    &__animation {
      position: relative;
      display: inline-block;
      cursor: pointer;
      &:after {
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f061';
        font-weight: 900;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
      }

      &:hover {
        padding: .7em 3em .7em 1em;
      }

      &.button--small {
        &:hover {
          padding: .4em 3em .4em 1em;
        }

        &:after {
          top: unset;
        }
      }
    }
    &.button--copy_error {
      &:hover{
        background-color: $midnight-blue;
      } 
      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0c5';
        font-weight: 900;
        left: 10px;

      }
      background-color: $ocean-blue;
      padding: .25em 1.2em .25em 2.5em;
      font-weight: $base-font-regular;
      position: relative;
      left: 25%;
    }


    &__icon--animation {
      cursor: pointer;
      transition: font-size 0.2s;
      font-size: 1em;

      &:hover {
        font-size: 1.1em;
      }
    }

    &__arrow:after, &__arrow--sm:after, &__arrow--lg:after{
      left: 130%;
      right: 1%;
      top: .75em;
    }

    &__arrow:hover:after {
      left: 85%;
    }

    &__arrow--sm:hover:after {
      left: 70%;
    }

    &__arrow--lg:hover:after {
      left: 92%;
    }

    &__animation--md:hover {
      padding: .65em 3em .65em 1em;
    }

    &__arrow--white {
      &:after {
        position: absolute;
        color: $white;
        font-family: 'FontAwesome';
        content: '\f061';
        font-weight: 900;
      }
    }

     /******* Other States ******/
     &__bulkgranules {
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f51e';
        font-weight: 900;
        left: 10px;
      }

      &.form-group__element:hover:before {
        visibility: visible;
      }
    }

    &__bulkgranules:hover:before{
      visibility: hidden;
    }

    &__bulkgranules.button__arrow--md:hover:after {
      left: 85%;
    }

    &__bulkgranules--operations {
      background-color: $light-green;

      &:before {
        content: '\f1b3';
      }
    }

    &__bulkgranules--delete {
      background-color: $delete-btn-bg-color;

      &:hover {
        background-color: $delete-btn-hover-bg-color;
      }

      &:before {
        content: $delete-btn-icon;
      }
    }

    &__bulkgranules--reingest {
      background-color: $light-green;

      &:before {
        content: '\f233';
      }
    }

    &__bulkgranules--recovery {
      background-color: $light-green;

      &:before {
        content: '\f247';
      }
    }

    &__kibana_open{
      background-color: $light-green;
      position: relative;
      margin-top: .5em;
      display: inline-block;

      &:before{
      color: $white;
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f360';
      font-weight: 900;
      left: 10px;
      }
    }

     &__addcollections {
      float: right;
      margin-top: 1em;
    }

    &__deletecollections {
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f187';
        font-weight: 900;
        left: 10px;

      }
    }

    &__deletecollections:hover:before{
      color: $white;
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f187';
      font-weight: 900;
      visibility: hidden;
    }

    &__goto {
      background-color: $light-green;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0a9';
        font-weight: 900;
        left: 10px;
      }

      &.form-group__element--right {
        &:hover:before {
          visibility: visible;
        }
      }
    }

      &__goto:hover:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0a9';
        font-weight: 900;
        visibility: hidden;
      }

    &--tab {
      border: none;
      background: none;
      padding-bottom: .5em;
      margin-right: 1em;
      color: $grey;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      &:hover {
        color: $dark-grey;
      }
    }

    //This is temporary until BatchAsyncCommands is refactored
    &__group {
      background-color: $ocean-blue;
      position: relative;

      &:before{
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f111';
        font-weight: 900;
        left: 10px;

      }
    }

    &__filter {
      background-color: $light-green;

      &:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f06e';
        font-weight: 900;
        left: 10px;
      }

      &:hover {
        background-color: $light-green;
      }

      &__expanded {
        background-color: darken($light-green, 13%);

        &:hover {
          background-color: darken($light-green, 13%);
        }
      }
    }

    &__reset-filter {
      background-color: $grey;
      margin-right: 15px;

      &:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f057';
        font-weight: 900;
        left: 10px;
      }

      &:hover {
        background-color: darken($grey, 13%);
      }
    }

    &__apply-filter {
      background-color: $light-green;

      &:before {
        color: $white;
        position: absolute;
        font-family: 'FontAwesome';
        content: '\f0c7';
        font-weight: 900;
        left: 10px;
      }

      &:hover {
        background-color: darken($light-green, 13%);
      }
    }

    &__legend {
      background-color: $orange;
    }

    &__row {
      background-color: $white;
      padding: .65em 1.1em;
      font-size: 1.1em;
      line-height: 1.1em;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);

      &--check {
        background-color: transparent;
        box-shadow: none;
        pointer-events: none;
        &:before {
          content: '\f00c';
          font-family: 'FontAwesome';
          background-color: transparent;
          color: $black;
          font-weight: 900;
        }
      }
      &--download {
        &:before {
          content: '\f0ab';
          font-family: 'FontAwesome';
          color: $light-green;
        }
      }
      &--delete {
        background-color: transparent;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        &:before {
          content: '\f187';
          font-family: 'FontAwesome';
          background-color: transparent;
          color: $grey;
          font-weight: 900;
        }

        &:hover {
          color: $dark-grey;
        }
      }
    }
}
