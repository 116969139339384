.chart__box {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.chart__container {
  height: 400px;
}

.chart__bar {
  fill: $midnight-blue;
}

.axis__tick,
.axis__line {
  stroke: $light-grey;
}

.axis__text {
  font-size: 10px;
  fill: $light-grey;
}

/* Tooltips */

.tooltip {
  margin-left: 1em;
  margin-top: 1em;
  position: fixed;
  pointer-events: none;
  transition: all 0.1s;
  z-index: 99;

  &.show {
    opacity: 1 !important;
  }
}

.tooltip__inner {
  padding: 1em;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background: #FFF;
  max-width: 240px;
}

.tooltip--blue {
  @extend .tooltip;
  .tooltip-inner {
    @extend .tooltip__inner;
    background-color: $ocean-blue;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $ocean-blue;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $ocean-blue;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $ocean-blue;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $ocean-blue;
  }
}

.tooltip--light {
  @extend .tooltip;
  .tooltip-inner {
    @extend .tooltip__inner;
    color: $ocean-blue;
    background-color: $white;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $white;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $white;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $white;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $white;
  }
}

/* Chart Graphic */

.clusters rect {
  fill: transparent;
  stroke: #555;
  stroke-dasharray: 5 2;
  stroke-width: 1px;
}

text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serf;
  font-size: 14px;
  fill: #444444;
}

rect {
  ry: 5;
  rx: 5;
}

.node rect {
  stroke: #555;
  fill: #fff;
  stroke-width: 1px;
}

.edgePath path {
  stroke: #555;
  stroke-width: 1.5px;
}

.cluster .label {
  display: none;
}

.terminus rect {
  ry: 25px;
  rx: 25px;
  fill: #ffda75;
}

.Succeeded rect {
  fill: #2bd62e;
}

.InProgress rect {
  fill: #53c9ed;
}

.Cancelled rect {
  fill: #ddd;
}

.Failed rect {
  fill: #de322f;
}

.Stopped rect {
  fill: orange;
}

svg:not(:root):not(.svg-inline--fa) {
  display: block;
  margin: 0 auto;
  overflow: visible;
}
