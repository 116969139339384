$root-font-size: 14px;

/* Main Font */
$base-font-family: 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-light: 300;
$base-font-italic: 400i;
$base-font-regular: 400;
$base-font-semibold: 600;
$base-font-bold: 700;
$base-font-weight: $base-font-regular;
$base-font-size: 1rem;
$base-line-height: 1.5;

/* Data Font */
$code-font-family: 'Inconsolata', monospace;
$code-font-size: 1rem;
$code-line-height: 1;