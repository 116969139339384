.pulse {
  position: relative;
}

.pulse__dot {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 30px;
  top: .4em;
  z-index: 10;
  background-color: #fff;
  border: 5px solid $light-green;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  right: -1.8em;
  display: none;
}

.pulse__ring {
  border: 11px solid $light-green;
  background: transparent;
  border-radius: 60px;
  height: 48px;
  width: 48px;
  -webkit-animation: pulse 2s linear;
  -moz-animation: pulse 2s linear;
  animation: pulse 2s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0.0;
  }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0.0;
  }
}

@-webkit-keyframes "pulse" {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
}
