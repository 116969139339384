.pre-style {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.table {
  &:not(.sub-table) {
    width: 100%;
    line-height: 1.6em;
    overflow-x: scroll;
    border-radius: 8px 8px 0px 0px;
    scroll-behavior: smooth;
  }

  .content-fit {
    width: fit-content!important;
  }

  .Collapsible {
    max-width: 500px;
  }

  .thead, .tbody {
    width: fit-content;
    min-width: 100%;
    overflow: hidden;
  }

  .thead {
    background-color: $ocean-blue;
    .th {
      color: $white;
      font-weight: $base-font-semibold;
      padding: 1em 1.5em;
      font-size: .86em;

      &.no-resize {
        border-right: 2px solid $lightest-grey;
        padding: 1em 1.5em;
      }
    }
  }

  .tbody {
    & > .tr {
      border-bottom: 1px solid $lightest-grey;
      .td {
        font-size: .86em;
        padding: 1em 2em;
        vertical-align: middle;
        overflow: hidden;
        &:hover {
          overflow: auto;
        }

        &.table__checkbox {
          padding: 1em 1.5em;
        }

        /*
         * From https://stackoverflow.com/questions/7492062/css-overflow-scroll-always-show-vertical-scroll-bar/10100209#10100209
         * The horizontal scrollbar does not always show, depending on the user's OS settings.
         * This forces a bar to render.
        */
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .5);
          box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
      }
      .table__main-asset {
        font-weight: $base-font-semibold;
      }
      &:nth-child(odd) {
        background-color: darken($background-white, 2%);
      }
    }
  }
}

.sub-table {
  .thead .th {
    background-color: $background-white;
    color: $black;
  }
}

.table__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1em;

  div {
    flex: 0 32%;
    align-items: center;
  }

  .list__filters--item {
    margin: 0 .3em;
  }

  .simple-pagination {
    padding: 20px;
  }

  input {
    width: 2em;
    text-align: center;
    margin: 0;
    padding: .4em .7em;
  }

  & + .table--wrapper .table {
    border-radius: 0;
  }
}

.table--wrapper {
  position: relative;
}

.scrollButton {
  background-color: $ocean-blue;
  color: white;
  cursor: pointer;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  align-items: center; 
  align-content: center;
  flex-wrap: wrap;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  position: -webkit-sticky;
  position: sticky;
  bottom:50%;

  div {
    flex: 0 0 100%;
    text-align: center;
  }
}

.scrollButtonLeft {
  float:left;
}

.scrollButtonRight {
  float: right;
}

.dropdown-wrapper {
  text-align: right;
}

.list__filters {
  margin: 1.5em 0 .5em;
  display: flex;
  @extend .clearfix;
  label {
    font-size: .86em;
  }

  &--item {
    display: inline-block;
    vertical-align: bottom;
    position: relative;

    .dropdown {
      &--small {
        width: 15em;
      }
      &--medium {
        width: 25em;
      }
      &--large {
        width: 35em;
      }
    }
  }
}

.table__filters {
  &--wrapper {
    flex-direction: column;
    background: $white;
    padding: 0.5em 2em 2em 2em;
    margin: 1em 0;
    box-shadow: $shadow__default;
  }

  &--header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $lightest-grey;
    margin-bottom: 15px;
  }

  &--header-title {
    display: flex;
    justify-content: space-between;
  }

  &--header-title-text {
    padding-top: 1em;
    display: flex;
    font-weight: 700;
  }

  &--header-title-close {
    display: flex;
    cursor: pointer;
    padding-top: 1em;

    &:after {
      color: $black;
      position: absolute;
      font-family: 'FontAwesome';
      content: '\f00d';
      font-weight: 600;
      // left: 10px;
    }
  }

  &--header-subtitle {
    padding-bottom: 15px;
  }

  &--checkboxes {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }

  &--footer {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
  }

  &--filter {
    display: flex;
    align-items: center;
    width: 250px;
    padding-top: 15px;

    label {
      margin: 0 0.5em;
    }
  }
}

.table__legend {
  margin-right: 1em;
}

.group-action--wrapper {
  background-color: $white;
  padding: 1em 1em 0.4em 1em;
  border: 1px solid $border-grey;
  box-shadow: $shadow__default;

  h4 {
    font-size: 0.86em;
  }

  p {
    font-size: 0.86em;
    padding-bottom: 1.25em;
  }
}

.list-action-wrapper {
  display: flex;
  flex-wrap: wrap;

  &:not(.no-actions) {
    align-items: flex-start;
  }

  &.no-actions {
    .filters {
      margin: 1em 0;
    }
  }
}

.list-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-grow: 1;
  margin-top: 3em;

  .form--controls {
    flex-grow: 1;
    margin-bottom: 1em;
  }
}

.list-view {
  position: relative;
  margin-top: 10px;
  /*border-bottom: 1px solid #E2DFDF;*/
  padding-bottom: 10px;
  width: 100%;
}

.list__wrapper{
  border: 1px solid $border-grey;
  box-shadow: $shadow__default;
  border-radius: 10px;

  .simple-pagination {
    margin: initial;
    border: initial;
    box-shadow: initial;
  }

}

.list--errors {
  tbody tr td:first-of-type,
  .tbody .tr .td:first-of-type {
    max-width: 300px;
  }
}

.resizer {
  display: inline-block;
  background-color: $lightest-grey;
  background-clip: content-box;
  padding: 0 10px;
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action:none;

  &.isResizing {
    background-color: $grey;
  }
}

.error__report, .report__table {
  .Collapsible {
    display: flex;
    flex-direction: column;

    .Collapsible__trigger {
      order: 1;
    }

    .Collapsible__contentInner {
      max-width: 230px;
    }
  }
}

.report__table {
  .Collapsible__trigger {
    align-self: flex-start;
    cursor: pointer;
    margin: 1em 0;
  }
}

// Overview Number Card Group //

.overview-num__wrapper {
  @extend .clearfix;
  margin-top: 1.5em;
  .overview-num, .overview-num:visited {
    width: 122px;
    height: 85px;
    background-color: #fff;
    padding: 1.5em 1em .5em;
    display: inline-block;
    border-radius: .5em;
    border: 1px solid lighten($lightest-grey, 4%);
    box-shadow: $shadow__default;
    text-align: center;
    font-size: .86em;
    color: $lighter-grey;
    margin: .8em 1.2em .5em 0;
    border-bottom: 0;
    overflow: hidden;
    clear: right;
    position: relative;
  }

  ul li {
    display: inline-block;

    .num-status {
      padding: 5px 0px 7px 0px;
      color: $ocean-blue;
      font-weight: 700;
      width: 100%;
      display: inline-block;
      text-align: center;
      position: absolute;
      top: 8px;
      left: 0px;
      border-bottom: 1px solid $lightest-grey;
    /*
      &--completed {
        background-color: $light-green;
      }

      &--failed {
        background-color: $error-red;
      }

      &--running {
        background-color: $orange;
      }
    */
    }
  }
  
}

.timeline--processing--overall {
  background-color: $white;
  padding: 1.5em 1em .5em;
  display: inline-block;
  border-radius: .5em;
  border: 1px solid $border-grey;
  box-shadow: $shadow__default;
  text-align: center;
  font-size: .86em;
  width: 615px;
  height: 70px;
  margin: .8em 1.5em .8em 0;
  /*float: left;*/
  color: #8C8C8C;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s ease 0s;
  overflow: hidden;
  clear: right;
  display: flex;
  flex-direction: row;
  /*font-size: .86em;
  color: $lighter-grey;
  margin: 3em 0;*/
  li {
    display: inline-block;
    position: relative;
    /*margin-right: 2em;
    padding-right: 10%;*/
    flex: 1;
    /*&:after {
      content: '';
      background-size: 15px 25px;
      background-repeat: no-repeat;
      width: 15px;
      height: 25px;
      fill: $light-green;
      display: block;
      top: 1em;
      right: 15%;
      position: absolute;
    }*/
    &:last-child {
      margin-right: 0;
      &:after {
        background-image: none;
      }
    }
  }
}

/*.timeline--processing--failed {
  color: $error-text-red;
}*/

.num--large {
  display: block;
  font-size: 2.4em;
  font-weight: $base-font-semibold;
  color: $midnight-blue;

  &--tooltip{
    position: relative;
    top: 17px;
    text-align: center;
    font-size: 1.5em;
    display: initial;
  }

  &--yellow{
    color: $orange;
  }

  &--red{
    color: $error-red;
  }

  &--blue{
    color: $midnight-blue;
  }
}

.num--medium {
  display: block;
  font-size: 1.7em;
  font-weight: $base-font-semibold;
  color: $midnight-blue;

  &--yellow{
    color: $orange;
  }

  &--red{
    color: $error-red;
  }
}

.form--controls {
  @extend .clearfix;
}

.table__main-asset {
  a, a:visited {
    color: $black;
    border-bottom: 1px solid $lightest-grey;
    &:hover {
      border-bottom: 1px solid $ocean-blue;
      color: $ocean-blue;
    }
  }
}

// Table Sorting Feature //

.table__sort {
  cursor: pointer;
  position: relative;
  padding-right: 10px;
  &:after {
    color: $white;
    position: absolute;
    font-family: 'FontAwesome';
    content: '\f0dc';
    font-weight: 900;
    width: 10px;
    height: 13px;
    background-size: 10px 13px;
    display: inline-block;
    margin-left: .5em;
    margin-top: 0em;
  }
}

.table__sort--asc, .table__sort--desc {
  &:after {
    content: ' ';
    width: 8px;
    height: 8px;
    background-size: 8px 8px;
    display: inline-block;
    margin-left: .5em;
  }
}

.table__sort--desc {
  &:after {
    color: $white;
    position: absolute;
    font-family: 'FontAwesome';
    content: '\f0dd';
    font-weight: 900;
    top: 10px;
  }
}
.table__sort--asc {
  &:after {
    color: $white;
    position: absolute;
    font-family: 'FontAwesome';
    content: '\f0de';
    font-weight: 900;
    top: 15px;
  }
}

// Table Progress Feature //

.table__progress {
  width: 100%;
}

.table__progress--name {
  font-weight: $base-font-regular;
}

.table__progress--outer {
  display: flex;
  align-items: center;
}

.table__progress--bar {
  display: inline-block;
  background-color: $ocean-blue;
  height: 6px;
}

.table__progress--bar--failed {
  background-color: $error-red;
  border-left: 1px solid #fefefe;
}

.table__progress--text {
  font-size: 0.8em;
  margin-left: 6px;
}

.table__error {
  color: $error-red;
}

.pdr__progress {
  margin-top: 1em;
  .table__progress--bar,
  .table__progress--outer {
    height: 10px;
  }
  .table__progress--text {
    top: -3px;
  }
  .table__progress--outer {
    background-color: $lightest-grey;
  }
}

// Table - Granule Status //

.table__main-asset {
  .granule__status, .granule__status:visited {
    color: $ocean-blue;
  }
}

/**********************************
  Table Form Options
***********************************/
.form__options{
  background-color: $white;
  height: 45px;
  padding: 21px 35px 0px 35px;
  display: flex;
  flex-direction: row;
  border-radius: 10px 10px 0 0;

}

.form__options li{
  display: inline-block;
  font-weight: 500;
  font-size: $base-font-size;
  flex: 1;
}

.options input[type=text] {
  width: 25px;
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 5px;
}

.amount {
  text-align: right;
}

.pager .pagination {
  margin: 0;
}
.data-record{
  font-weight: 700;
}
